//black theme
$main-header-border: #49515E;
$active-navbar-menu-color: #d69e2d;
$main-background-color: black;
$main-sidebar-background-color: #2C2C34;
$main-text-color: white;
$main-hover-color: #321fdb;
$content-background-color: #0B0B17;
$box-background-color: #191924;
$tabs-text-color: #7267D3;
$dropdown-color: #3c8dbc;

.skin-black {
  font-family: "Roboto", Helvetica, Arial, sans-serif;

  .container {
    color: $main-text-color;
    .price-item {
      .sub-currency {
        color: $main-text-color; }
      p {
        color: $main-text-color; } }
    .black-text {
      color: $main-text-color !important;
      p, li {
        color: $main-text-color !important; } } }

  .main-header {
    border-bottom: 1px solid $main-header-border !important;
    .btn {
      color: $main-text-color;
      background: $dropdown-color !important;
      border-color: $dropdown-color; }
    .logo {
      background-color: $main-background-color !important;
      border-right: 1px solid $main-header-border !important;
      height: 52px !important;
      &:hover {
        background-color: $main-background-color !important; } }
    .navbar {
      background-color: $main-background-color;
      & > .sidebar-toggle {
        border-right: 1px solid $main-header-border; }
      .navbar-custom-menu {
        .navbar-nav {
          & > li {
            & > a {
              border-left: 1px solid $main-header-border !important; } } } }
      & .navbar-right {
        & > li {
          & > a {
            border-left: 1px solid $main-header-border !important; } } }
      .nav {
        .dropdown-menu {
          background: $dropdown-color !important;
          border-color: $dropdown-color;
          li {
            a {
              color: $main-text-color;
              background: $dropdown-color !important;
              border-color: $dropdown-color; } } }
        li {
          a {
            color: $main-text-color; } }
        &  > .active {
          & > a {
            background: $active-navbar-menu-color !important;
            color: $main-text-color; } } } } }

  .wrapper {
    background-color: $main-sidebar-background-color; }

  .main-sidebar {
    background-color: $main-sidebar-background-color; }

  .left-side {
    background-color: $main-sidebar-background-color; }

  .sidebar-menu {
    & > li {
      & > a {
        background-color: $main-sidebar-background-color !important;
        border-left-color: $main-background-color !important;
        & > span {
          color: $main-text-color !important; } }
      &.active {
        & > a {
          background-color: $main-header-border !important;
          border-left-color: $main-background-color !important; }
        &:hover > a {
          background-color: $main-hover-color !important;
          border-left-color: $main-background-color;
          & > span {
            background-color: $main-hover-color !important;
            color: $main-text-color; } } }
      &:hover {
        & > a {
          background-color: $main-hover-color !important;
          border-left-color: $main-background-color;
          & > span {
            background-color: $main-hover-color !important;
            color: $main-text-color; } } }
      & > .treeview-menu {
        background-color: inherit !important; } }
    .treeview-menu {
      background-color: $main-sidebar-background-color !important;
      border-left-color: $main-background-color !important;
      li {
        a {
          padding: 12px 5px 12px 15px;
          background-color: $main-sidebar-background-color !important;
          border-left-color: $main-background-color !important; }
        &:hover {
          & > a {
            background-color: $main-hover-color !important;
            border-left-color: $main-background-color; } } } } }

  .content-wrapper {
    background-color: $content-background-color !important; }

  .right-side {
    background-color: $content-background-color !important; }

  .content-header {
    .breadcrumb {
      background-color: $main-sidebar-background-color !important;
      li {
        &.active {
          color: $main-text-color; }
        a {
          color: $tabs-text-color; } } } }
  .box {
    background-color: $box-background-color !important;
    border-top: 3px solid $main-sidebar-background-color;
    .box-header {
      background-color: $main-sidebar-background-color !important;
      color: $main-text-color;
      &.with-border {
        border-bottom: 1px solid $main-sidebar-background-color; }
      .help-info {
        background-color: $main-sidebar-background-color;
        color: $main-text-color; }
      .tip-info p, .tip-info b, .tip-info ul, .tip-info li {
        color: $main-text-color !important; } }
    .box-footer {
      background-color: $box-background-color;
      border-top: 1px solid $main-header-border; } }

  .help-info {
    background-color: $main-sidebar-background-color;
    color: $main-text-color; }

  .dataTable {
    border-top: 1px solid $main-sidebar-background-color;
    border-bottom: 1px solid $main-sidebar-background-color;
    thead {
      tr {
        background-color: #2C3E50 !important;
        th {
          border-bottom: 1px solid $main-sidebar-background-color; } } }
    tr {
      color: $main-text-color;
      td {
        border-top: 1px solid $main-sidebar-background-color;
        border-bottom: 1px solid $main-sidebar-background-color; } }
    tbody {
      tr:nth-child(2n+1) {
        background-color: $main-sidebar-background-color; } }
    tfoot {
      tr {
        th {
          border-top: 1px solid $main-sidebar-background-color; } } } }

  .pinned, .scrollable {
    background-color: $box-background-color;
    overflow-x: auto; }

  .box-title, label {
    color: $main-text-color; }

  .panel-success {
    color: $main-text-color;
    background-color: $main-sidebar-background-color;
    border-color: $main-sidebar-background-color !important;
    .panel-heading {
      color: $main-text-color;
      background-color: $main-sidebar-background-color;
      border-color: $main-sidebar-background-color !important; } }

  .panel-default {
    background-color: $box-background-color; }

  input, select, textarea, button.btn-default {
    background-color: $main-sidebar-background-color;
    color: $main-text-color; }

  .nav-tabs-custom {
    background-color: $content-background-color;
    color: $main-text-color;
    .nav-tabs {
      border-bottom-color: $content-background-color;
      li {
        border: none;
        a {
          border: none;
          color: $tabs-text-color; }
        &.active {
          a {
            background-color: $main-sidebar-background-color;
            color: $main-text-color; } } } }
    .tab-content {
      border: none;
      background-color: $main-sidebar-background-color;
      .tab-pane {
        background-color: $main-sidebar-background-color; } } }

  hr {
    background-color: $main-sidebar-background-color; }

  ul {
    .chosen-choices {
      background-color: $main-sidebar-background-color;
      color: $main-text-color;
      background-image: none;
      li.search-choice {
        background-color: $main-sidebar-background-color;
        color: $main-text-color;
        background-image: none; } } }
  .chosen-container {
    .chosen-drop {
      background-color: $main-sidebar-background-color;
      color: $main-text-color;
      .chosen-results {
        .active-result {
          color: $main-text-color; } } } }

  h1, h2, h3, h4, h5, h6 {
    color: $main-text-color; }

  // --- LANDING
  #app {
    background-color: $main-background-color !important; }

  footer {
    background-color: $main-sidebar-background-color;
    color: $main-text-color;
    border-top: 1px solid $main-header-border !important; }

  .bg-aqua {
    background-color: #74A5B0 !important; }

  .bg-green {
    background-color: #5E8775 !important; }

  .bg-yellow {
    background-color: #B8A380 !important; } }
