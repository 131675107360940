// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

@import "../../public/plugins/dataTables.bootstrap.css";

@import "../../public/plugins/datepicker3.css";

@import "../../public/plugins/daterangepicker.css";

@import "~font-awesome/scss/font-awesome.scss";

@import "../assets/css/main.css";

@import "../../public/plugins/daterangepicker.css";

@import "~bootstrap-duration-picker/src/bootstrap-duration-picker";
@import "../assets/css/chosen.min.css";
@import "../assets/css/custom.css";

@import "dark-theme";

.table form {
  display: inline; }

.box-header {
  padding-bottom: 10px;
  .box-tools {
    float: right; } }

.navbar-default {
  background-color: #000;
  border-color: #333;
  .navbar-nav {
    .active {
      a {
        color: #eee;
        background-color: #ef8d30;
        &:hover {
          color: #eee;
          background-color: #ef8d30; } } }

    li {
      a {
        color: #eee;
        &:hover {
          color: #fff;
          background-color: #ef8d30; } } } }

  .navbar-header {
    white-space: nowrap;
    break-inside: avoid-column; }
  .navbar-brand {
    break-inside: avoid-column;
    color: #eee;
    white-space: nowrap;
    img {
      width: 110px;
      position: relative;
      top: -5px;
      display: inline-block;
      margin-right: 10px; }
    &:hover {
      color: #fff; } } }

#desc,#home {
	background-image: url(/images/main-background.jpeg);
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	background-size: cover;
	height: 95vh !important; }
.scroll-down {
	font-size: 50px {
    color: white; }
	left: 72%;
	position: absolute;
	bottom: 11px;
	margin-left: -25%;
	color: white; }

.scroll-down:hover {
	color: white;
	opacity: 0.7; }

#navigation {
	background-color: #2F2F2F {
    border-color: #333; } }

.navbar-nav,.navbar-collapse {
	color: #337AB7; }

#intro {
	border-top: inherit;
	padding-bottom: 50px; }

.navbar-default .navbar-nav li a {
	color: #fff;
	font-weight: 400; }

#intro p,#intro b,#intro h1 {
 }	//color: white

#intro table p,#intro table b,#intro table h1 {
	color: #777; }

.navbar-nav {
	text-transform: uppercase; }

.dropdown-menu {
    background-color: #dfd78e; }

.navbar-nav > li > .dropdown-menu li {
	width: 100%; }

.navbar-nav > li > .dropdown-menu li:first-child {
	border-bottom: black solid 1px; }

.open > .dropdown-toggle {
	background-color: #e7e2b6 !important; }

#headerwrap {
    background-color: inherit; }

#headerwrap h2 {
	color: white;
	font-size: 55px; }

#headerwrap .btn-primary {
    font-size: 25px; }

@media only screen and (max-width: 760px) {
	#headerwrap .btn-primary {
		margin-bottom: 20px; } }

@media only screen and (min-width: 1280px) {
	#headerwrap {
		padding-top: 120px; } }

@media (max-width: 767px) {
	.navbar-nav .open .dropdown-menu {
		width: 100%; }

	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: #333;
		font-size: 16px; }

	.navbar-default .navbar-nav .open .dropdown-menu > li {
		border-bottom: none;
		margin-left: 15px; } }

.navbar-header .navbar-brand {
	color: #fff; }

.navbar-header .navbar-brand:hover {
	color: #EF8D30; }

.main-custom-text {
	margin-top: 70px; }

.main-custom-text p {
	font-size: 30px; }

.main-custom-text h1 {
	font-size: 55px; }

.table-responsive {
	overflow-x: inherit; }

.mobile-filter>div>a {
	color: #333; }

.mobile-filter > .open > .dropdown-toggle {
	background-color: #f2f2f2 !important; }

.mobile-filter .dropdown-menu {
	background-color: #f2f2f2; }

.mobile-filter .span-sorter {
	padding: 3px 20px; }

@media screen and (max-width: 767px) {
	.table-responsive {
	    overflow-y: inherit; } }

.table-delete-button {
	margin-left: 10px; }

.daterangepicker.dropdown-menu {
	background-color: white; }

.callout-warning > p {
	color: red !important; }

#intro .breadcrumb {
	background-color: inherit;
	padding: 0px; }

.period-picker .dropdown-menu {
	background-color: #fff !important; }

.period-picker b {
	color: #333333 !important; }

.period-picker .open > .dropdown-toggle {
	background-color: #fff !important; }

.period-picker .dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%; }

@media (max-width: 767px) {
	.period-picker .dropdown-submenu > .dropdown-menu {
		top: initial;
		left: initial; }
	.daterangepicker .ranges li:last-child {
	    display: none !important; }
	.datarange-btn {
		clear: both; }
	.pickers-container, .period-picker {
		float: left !important; } }
@media screen and (min-width: 768px) {
	.carousel-indicators {
		bottom: -50px; } }
.main-page-price {
  clear: both;
  display: inline-block;
  .main-currency {
	  font-size: 90px;
	  float: left;
	  color: #337ab7; }
  .sub-currency {
	  font-size: 40px;
	  float: right; } }
.price-header {
	font-size: 36px;
	font-weight: 400; }
#price,#video-section {
	padding: 50px 0px; }
.seo_h2_white {
	color: white;
	font-size: 36px; }
.seo_h2_bottom {
	font-size: 36px;
	padding-bottom: 30px; }
#headerwrap h1 {
	font-size: 50px; }
.tip-info {
  background-color: #e6e6e6;
  border-left: 6px solid #dfd78e;
  padding: 10px 15px;
  margin-bottom: 15px;
  color: #555; }
.tip-info p, .tip-info b, .tip-info ul,.tip-info li {
  color: #555 !important; }
.dataTable thead tr {
  background-color: #a0be86;
  color: #fff;
  text-transform: uppercase; }
.dataTable tbody tr:nth-child(2n+1) {
  background-color: #fff; }
.dataTable tbody tr {
  color: #000; }
.dataTable tbody tr td {
	border-color: #eaeaea; }
.btn-sm {
  text-transform: uppercase; }
@media screen and (max-width: 767px) {
  #headerwrap h1 {
	  font-size: 25px; }
  #desc h2, #home h2 {
	  font-size: 22px; }
  #desc h3, #home h3 {
	  font-size: 18px; }
  .scroll-down {
	  display: none; }
  .navbar-header .navbar-brand {
	  width: 150px;
	  height: 85px; }
  .navbar-header .navbar-brand img {
	  float: none !important; }
  .navbar-header .navbar-brand b {
	  position: relative;
	  display: block;
	  float: none !important; }
  .main-custom-text h1 {
	  font-size: 26px; }
  .main-custom-text p {
	  font-size: 20px; }
  .navbar-header.small-header a {
	  height: 50px; } }
.navbar-header .navbar-brand img {
	width: 166px;
	top: -10px;
	float: left; }
.navbar-header .navbar-brand b {
	float: right;
	display: block;
	height: 45px; }
.margin-button-bottom {
	margin-bottom: 10px !important; }
.margin-button-top {
	margin-top: 10px !important; }
.margin-button-left {
	margin-left: 10px !important; }
.margin-button-right {
	margin-right: 10px !important; }
.form-group label {}
.form-group label {
  text-transform: uppercase !important; }
select[name="group_1"] {
	width: 100%;
	height: 36px;
	margin-bottom: 15px; }
::-moz-placeholder {
  text-transform: uppercase; }
::-webkit-input-placeholder {
  text-transform: uppercase;
  -ms-input-placeholder: {
    text-transform: uppercase; } }
::placeholder {
  text-transform: uppercase; }
select[name="group_1"] {
  text-transform: uppercase; }
.headers-container.background-dark {
  background-color: rgba(0, 0, 0, 0.3); }
.white-color {
	color: #fff !important; }
#features .tab-pane {
  padding-top: 50px; }
#features .nav-tabs > li > a,#features .nav-tabs > li.active > a:hover, #features .nav-tabs > li.active > a:focus {
  background-color: inherit;
  border: none; }
#features .nav-tabs > li.active h3 {
  font-weight: bold; }
#features .nav-tabs > li {
	width: 20%; }
#features .nav-tabs > li:not(.active) > a:hover {
  background-color: #e6e6e6; }
#features .nav-tabs > li.active ,#features .nav-tabs > li:hover, #features .nav-tabs > li:focus {
  border-bottom: 10px solid #337ab7; }
.tab-list li {
  list-style-type: none;
  font-size: 20px; }

.tab-list li:before {
  color: #337ab7;
  content: "\f24e";
  font-family: FontAwesome;
  display: inline-block;
  padding-right: 10px; }
#features .tab-text {
  margin-left: 50px; }
.header-blue {
  color: #337ab7; }
.header-bold {
  font-weight: bold; }
#features .tab-text p {
 font-weight: 400 !important;
 font-size: 18px; }
#features .tab-text p.small-text {
 font-size: 14px !important; }
#features .tab-content .img-responsive {
	margin: 0 auto; }
.black-text p,.black-text li {
  color: #000 !important; }
#orange-background {
	color: #dd4b39 !important; }
.required-glyph {
	padding-right: 40px;
	color: #dd4b39; }
.required-glyph-select {
	padding-right: 60px !important; }
.has-feedback textarea {
	height: 72px; }
.tooltip-input {
	width: 95% !important;
	display: inline-block; }
.tooltip-question {
	float: right;
	font-size: 24px;
	color: #f57a20; }
.register-box-body {
	margin-bottom: 200px; }
.tooltip-question-checkbox {
	float: none; }
.orange-background {
	color: #dd4b39 !important; }
.required-glyph {
	padding-right: 40px;
	color: #dd4b39 !important; }
.custom-field-container .required-glyph {
	padding-right: 60px; }
.tooltip-asterisk {
	right: 20px; }
@media screen and (max-width: 768px) {
	.tooltip-input {
		width: 90% !important; }
	.box-header > .box-tools {
		position: inherit; } }
.groups-form .panel-body {
	padding: 0 15px; }
.active-arrow {
	color: #fff;
	background-color: #ef8d30; }
.active-arrow a {
	color: #fff !important; }
.red {
	color: red !important; }
.help-tip {
	display: inline-block;
	width: 310px;
	position: relative; }
.help-tip p {
	text-align: left;
	background-color: #f57a20;
	padding: 20px;
	width: 310px;
	position: absolute;
	border-radius: 3px;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	top: -20px;
	font-weight: 500; }
.help-tip p:before {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    border: 12px solid transparent;
    border-bottom-color: #f57a20;
    right: 140px;
    top: -24px; }
.help-tip p:after {
    width: 100%;
    height: 40px;
    content: '';
    position: absolute;
    top: -40px;
    left: 0; }
@media (min-width: 1280px) {
	.help-tip p {
		right: -120px; } }
.tooltip {
	width: 250px !important; }
.footer-table td {
	padding: 5px 10px; }
.footer-table tbody tr:not(:last-child),.footer-table thead tr {
	border-bottom: 1px solid #fff; }

#navigation {
	.btn {
		color: $main-text-color;
		background: $dropdown-color !important;
		border-color: $dropdown-color; }
	.dropdown-menu {
		background: $dropdown-color !important;
		border-color: $dropdown-color;
		li {
			a {
				color: $main-text-color;
				background: $dropdown-color !important;
				border-color: $dropdown-color; } } } }
