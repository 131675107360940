.bdp-input input {
    display: inline-block;
    margin-bottom: 3px;
    width: 60px;
}

.bdp-block {
    display: inline-block;
    line-height: 1;
    text-align: center;
    padding: 5px 3px;
}